import React from 'react';
import ContactBlock from 'components/ContactBlock';
import { ContactBlockProps } from 'components/ContactBlock/types';
import data from './contactBlocks.json';
import './Footer.scss';

const Footer = () => {
  const [headquater, ...contacts] = data as ContactBlockProps[];
  console.log('!!! headquater', headquater);

  return (
    <footer className="footer" id="kontakt">
      <div className="container">
        <div className="columns">
          <div className="column is-6">
            <ContactBlock
              className="contact-primary"
              label={headquater.label}
              people={headquater.people}
            />
          </div>
          <div className="column is-6">
            <div className="footer__contact-wrapper">
              {contacts.map(({ label, people }) => (
                <div className="footer__contact" key={label}>
                  <ContactBlock label={label} people={people} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
