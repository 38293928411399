import React, { ReactChild, useEffect } from 'react';
import loadScript from '../../helpers/loadScript';
import markerIcon from './marker.png';
import { Marker } from './types';
import './GoogleMap.scss';

const MAP_KEY = 'AIzaSyDfWF1sQyycCMnPP8EHxryA1mSIDjaZhp8';

type GoogleMapProps = {
  id: string;
  children?: ReactChild;
  markers: Marker[];
};

const GoogleMap = ({ id, children, markers }: GoogleMapProps) => {
  const setupGoogleMap = () => {
    const center = {
      lat: 54.089678430982666,
      lng: 22.93729003348533,
    };

    const map = new google.maps.Map(document.getElementById(id)!, {
      center,
      zoom: 13,
    });

    markers.map((marker) => {
      return new google.maps.Marker({
        map,
        position: marker,
        icon: {
          url: markerIcon,
        },
      });
    });
  };

  useEffect(() => {
    loadScript({
      url: `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}`,
      id: `${id}-script`,
    }).then(setupGoogleMap);
  }, []);

  return (
    <div className="map__container">
      <div id={id} className="map" />
      {children}
    </div>
  );
};

export default GoogleMap;
