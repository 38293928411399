import React from 'react';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';
import { ContactBlockProps } from './types';
import './ContactBlock.scss';

const ContactBlock = ({ label, people, className = '' }: ContactBlockProps) => {
  return (
    <div className={`contact-block ${className}`} data-sal="slide-up" data-sal-delay="300">
      {label && <h3 className="contact-block__title">{label}</h3>}
      {people?.map(({ name, phone, mail, address }) => (
        <address className="contact-block__person">
          {mail && <a href={`mailto: ${mail}`}>{name}</a>} <br />
          {address && <DangerouslySetInnerHtml html={address} />}
          {phone && <a href={`tel: ${phone}`}>{phone}</a>} <br />
        </address>
      ))}
    </div>
  );
};

export default ContactBlock;
