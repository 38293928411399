import React, { FC, ElementType, SyntheticEvent } from 'react';

interface DangerouslySetInnerHtmlProps {
  type?: string;
  html?: string;
  element?: ElementType;
  className?: string;
  onClick?: (e: SyntheticEvent) => void;
}

const DangerouslySetInnerHtml: FC<DangerouslySetInnerHtmlProps> = ({ html, element, ...rest }) => {
  if (!html) {
    return null;
  }

  const Element = element || 'div';
  return <Element dangerouslySetInnerHTML={{ __html: html }} {...rest} />;
};

export default DangerouslySetInnerHtml;
