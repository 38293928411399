import { Link } from 'gatsby';
import React, { FC } from 'react';

import './Cta.scss';

type CtaProps = {
  label: string;
  url: string;
  onClick?: () => void;
  className?: string;
};

const Cta: FC<CtaProps> = ({ url, label, onClick, className = '' }) => {
  const Tag = url ? Link : 'button';

  const props = {
    className: `cta ${className}`,
    onClick: onClick,
    to: url,
  };

  return <Tag {...props}>{label}</Tag>;
};

export default Cta;
