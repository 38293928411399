import React, { ReactChild, useEffect, useRef, useState } from 'react';

type InViewProps = {
  config?: {
    rootMargin: string;
    threshold: number;
  };
  children: ReactChild;
};

const defaultConfig = {
  rootMargin: '0px 0px -10px 0px',
  threshold: 0,
};

const InView = ({ config = defaultConfig, children }: InViewProps) => {
  const [inView, setInView] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const initIntersectionObserver = (ref) => {
    const observer = new IntersectionObserver((entries, self) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setInView(true);

          self.unobserve(entry.target);
        }
      });
    }, config);

    observer.observe(ref);
  };

  useEffect(() => {
    if (!ref) {
      return;
    }
    initIntersectionObserver(ref.current);
  }, [ref]);

  return (
    <div ref={ref} data-sal="slide-up" data-sal-duration={600} data-sal-delay={100}>
      {inView ? children : null}
    </div>
  );
};

export default InView;
