import React from 'react';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';

import './SectionIntro.scss';
import Cta from 'components/Cta';

type SectionIntroProps = {
  title?: string;
  subtitle: string;
  skipAnimation?: boolean;
  className?: string;
  cta?: {
    label: string;
    url: string;
  };
};

const SectionIntro = ({
  title,
  subtitle,
  skipAnimation,
  cta,
  className = '',
}: SectionIntroProps) => {
  const animationProp = skipAnimation ? '' : 'slide-up';

  return (
    <div className={`section-intro ${className}`}>
      {title ? (
        <DangerouslySetInnerHtml
          html={title}
          element="h1"
          className="section-intro__title"
          data-sal={animationProp}
          data-sal-duration={600}
          data-sal-delay={100}
        />
      ) : null}
      {subtitle ? (
        <DangerouslySetInnerHtml
          className="section-intro__subtitle"
          html={subtitle}
          data-sal={animationProp}
          data-sal-duration={600}
          data-sal-delay={300}
        />
      ) : null}
      {cta ? <Cta className="section-intro__cta" label={cta.label} url={cta.url} /> : null}
    </div>
  );
};

export default SectionIntro;
