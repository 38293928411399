import React from 'react';
import { withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import useSiteMetadata from '../SiteMetadata';
import InView from 'components/InView';
import GoogleMap, { markers } from 'components/GoogleMap';
import SectionIntro from 'components/SectionIntro';
import '../../styles/all.scss';
import './Layout.scss';

const mapTitle = 'Spotkajmy się';

const mapSubtitle = `
  <p>
    Zapraszamy Cię na pierwsze spotkanie, które jest całkowicie bezpłatne i niezobowiązujące. Poznasz nas i nasz zakres usług. Nasz zespół postara się jak najlepiej odpowiedzieć na Twoje pytania.
  </p>
  <p>
    Serce finansów to miejsce, w którym znajdziesz pełen wachlarz usług związanych z kredytami.
    Nie musisz chodzić w wiele miejsc - wszystko znajdziesz w Sercu Finansów.
  </p>
`;

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="pl" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix('/')}img/serce-finansow-biuro.jpg`} />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <div className="layout__footer-wrapper">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1 page-section-wrapper">
              <SectionIntro title={mapTitle} subtitle={mapSubtitle} />
              <InView>
                <GoogleMap markers={markers} id="google-map" />
              </InView>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
