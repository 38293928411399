import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Hamburger from './Hamburger';
import navigation from './navigation.json';
import { NavItem } from './model';
import Dropdown from './Dropdown';

import logo from './serce-finansow-logo.svg';
import logoWhite from './serce-finansow-logo-alt.svg';
import './Navbar.scss';

const Navbar = () => {
  const [navigationActive, setNavigationActive] = useState(false);
  const [isAlternativeStyle, setAlternativeStyle] = useState(false);
  const navRef = useRef<HTMLElement>(null);
  const activeClass = `${navigationActive ? 'is-active' : ''}`;

  const toggleHamburger = () => {
    setNavigationActive(!navigationActive);
  };

  const handleScroll = () => {
    const navbarHeight = navRef.current!.clientHeight;
    const isMobile = window.innerWidth < 768;
    const alternativeStyleThreshold = isMobile ? 1 : window.innerHeight - navbarHeight;

    if (window.scrollY > alternativeStyleThreshold && !isAlternativeStyle) {
      setAlternativeStyle(true);
    }

    if (window.scrollY < alternativeStyleThreshold && isAlternativeStyle) {
      setAlternativeStyle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isAlternativeStyle]);

  return (
    <nav
      className={`navbar is-fixed-top is-transparent ${
        isAlternativeStyle ? 'is-white is-scrolled' : ''
      }`}
      role="navigation"
      aria-label="main-navigation"
      ref={navRef}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img
              className="logo"
              src={isAlternativeStyle ? logo : logoWhite}
              alt="Serce Finansów logo"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
